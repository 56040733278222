<template>
  <childFault :compChild="true" />
</template>
<script>
import childFault from './comp'
export default {
  components: { childFault },
  data () {
    return {

    }
  }
}
</script>
